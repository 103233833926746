import { useState } from "react";
import { formatNumberCompact } from "../../../utils/formatNumber";
import Footer from "../_generic/Footer";
import { GrowthText } from "../_generic/GrowthText";
import { Layout } from "../_generic/Layout";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";
import { TrendsLineChart } from "../_generic/TrendsLineChart";

const TopSearchProducts = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { h1 = "ERROR", data = [], dataTitle = "ERROR" } = slideContent;

    const DISPLAY_COUNT = 5;
    const [displayCount, setDisplayCount] = useState(DISPLAY_COUNT);

    const handleShowMore = () => {
        setDisplayCount((prev) => Math.min(prev + DISPLAY_COUNT, data.length));
    };

    return (
        <Layout showFull={showFull}>
            <div className="w-full mb-20 font-poppins">
                <div className="flex flex-col col-span-6 mx-[50px]">
                    <div className="flex flex-col mt-10 mb-16">
                        <h1 className="text-black text-[34px] font-medium">
                            {h1}
                        </h1>
                        <h2 className="text-eyvablue text-sm font-semibold leading-[42px]">
                            {dataTitle.toUpperCase()}
                        </h2>
                    </div>

                    <div className="flex flex-col gap-7 items-center justify-center">
                        <table className="w-full">
                            <thead className="text-xs text-black/50 pb-3 mb-5">
                                <tr>
                                    <th className="text-left font-normal">
                                        Product
                                    </th>
                                    <th className="text-right font-normal">
                                        Brand
                                    </th>
                                    <th className="text-right font-normal w-32">
                                        <div className="flex flex-col items-end">
                                            <span>Search</span>
                                            <span>Volume</span>
                                        </div>
                                    </th>
                                    <th className="text-right font-normal">
                                        12m Trend
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.slice(0, displayCount).map((item, id) => (
                                    <tr
                                        key={id}
                                        className="border-b border-black text-black text-sm"
                                    >
                                        <td className="py-3 font-normal leading-10 first-letter:capitalize flex items-center flex-row gap-3 ">
                                            <img
                                                src={item.imageURL}
                                                alt={item.title}
                                                className="w-10 h-10 mr-2"
                                            />
                                            <span className="truncate max-w-[300px]">
                                                {item.title}
                                            </span>
                                        </td>
                                        <td className="py-3 font-normal leading-10 text-right">
                                            {item.brand}
                                        </td>
                                        <td className="text-right font-medium py-3 leading-10">
                                            {formatNumberCompact(
                                                item.searchVolume,
                                            )}
                                        </td>
                                        <td className="py-3 w-[120px] text-right">
                                            <TrendsLineChart
                                                val={item.ts}
                                                backgroundColor="#FFFFFF"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {displayCount < data.length && (
                            <button
                                className="bg-purple text-white px-9 py-3 rounded-sm font-medium text-sm font-poppins"
                                onClick={handleShowMore}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                reportVersion={reportVersion}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopSearchProducts;
