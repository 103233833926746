import ReactEcharts from "echarts-for-react";

import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const colors = {
    one: "#EB966E",
    two: "#9A2888",
    three: "#272090",
};

const getOptions = ({ data, lang = "en" }) => {
    // Extract dates for the x-axis from one of the time series
    const dates = data[0].ts.map((entry) => entry.date);

    // Create a series for each topic in reverse order to control layering
    const series = data.map((topic) => {
        // Clone the data array
        let seriesData = topic.ts.map((entry) => ({
            value: entry.sv,
            label: { show: false },
        }));

        // Enable the label for the last data point
        if (seriesData.length > 0) {
            seriesData[seriesData.length - 1].label = {
                show: true,
                position: "right",
                formatter: topic.topic,
                // Add font style properties here
                fontStyle: "italic",
                fontWeight: "bold",
                fontFamily: "Poppins",
                fontSize: 14,
                distance: 20,
            };
        }

        return {
            animation: false,
            name: topic.topic,
            type: "line",
            data: seriesData,
            smooth: true,
            showSymbol: true,
            symbolSize: 0,
            itemStyle: {
                color: topic.itemColor, // Set the color for each series
            },
            lineStyle: {
                color: topic.itemColor, // Set the line color for each series
            },
        };
    });

    let xAxisText = "";

    let yAxisText = "Monthly Search Volume";
    if (lang === "de") {
        yAxisText = "Monatliches Suchvolumen";
    }

    const options = {
        grid: {
            left: "50px", // Adjust as needed
            right: "180px", // Adjust as needed
            top: "10px", // Adjust as needed
            bottom: "30px", // Adjust as needed
            containLabel: true,
        },
        tooltip: {
            trigger: "axis",
        },
        legend: {
            data: data.map((topic) => topic.topic),
            show: false,
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: dates,
            name: xAxisText,
            nameLocation: "middle",
            nameGap: 30,
            axisLabel: {
                formatter: function (value) {
                    const date = new Date(value);
                    return date.toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                    });
                },
                color: "#3A3A3A",
                margin: 20,
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: 400,
            },
            splitLine: {
                // Add this property for split lines
                show: true,
                lineStyle: {
                    type: "dashed", // Dashed line style
                    color: "#ddd", // You can change the color if needed
                },
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            name: yAxisText, // Y-axis title
            nameRotate: 90, // Rotate the Y-axis title
            nameLocation: "middle", // Position of the Y-axis title
            nameGap: 70, // Gap between the Y-axis title and the axis line
            axisLabel: {
                formatter: function (value) {
                    if (value >= 1000000) {
                        return (value / 1000000).toFixed(1) + "M"; // For millions
                    } else if (value >= 1000) {
                        return (value / 1000).toFixed(0) + "k"; // For thousands
                    }
                    if (value === 0) return null;

                    return (value / 1000).toFixed(1) + "k"; // For thousands
                    //return value; // For values less than 1000
                },
                color: "#3A3A3A",
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: 400,
            },
            splitLine: {
                // Add this property for split lines
                show: true,
                lineStyle: {
                    type: "dashed", // Dashed line style
                    color: "#ddd", // You can change the color if needed
                },
            },
        },
        series: series,
        nameTextStyle: {
            color: "#3A3A3A",
            fontSize: 14,
            fontFamily: "Poppins",
            fontWeight: 400,
        },
    };

    return options;
};

const Comparison = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    const {
        h1 = "ERROR",
        h2 = "ERROR",
        data = [
            {
                ts: [
                    {
                        sv: 382440,
                        date: "2020-11-01",
                    },
                    {
                        sv: 404550,
                        date: "2020-12-01",
                    },
                    {
                        sv: 465480,
                        date: "2021-01-01",
                    },
                    {
                        sv: 457920,
                        date: "2021-02-01",
                    },
                    {
                        sv: 453120,
                        date: "2021-03-01",
                    },
                    {
                        sv: 444520,
                        date: "2021-04-01",
                    },
                    {
                        sv: 397340,
                        date: "2021-05-01",
                    },
                    {
                        sv: 285940,
                        date: "2021-06-01",
                    },
                    {
                        sv: 274650,
                        date: "2021-07-01",
                    },
                    {
                        sv: 307640,
                        date: "2021-08-01",
                    },
                    {
                        sv: 292940,
                        date: "2021-09-01",
                    },
                    {
                        sv: 359040,
                        date: "2021-10-01",
                    },
                    {
                        sv: 385420,
                        date: "2021-11-01",
                    },
                    {
                        sv: 417580,
                        date: "2021-12-01",
                    },
                    {
                        sv: 458420,
                        date: "2022-01-01",
                    },
                    {
                        sv: 420920,
                        date: "2022-02-01",
                    },
                    {
                        sv: 475260,
                        date: "2022-03-01",
                    },
                    {
                        sv: 439860,
                        date: "2022-04-01",
                    },
                    {
                        sv: 397890,
                        date: "2022-05-01",
                    },
                    {
                        sv: 328340,
                        date: "2022-06-01",
                    },
                    {
                        sv: 316330,
                        date: "2022-07-01",
                    },
                    {
                        sv: 328910,
                        date: "2022-08-01",
                    },
                    {
                        sv: 343740,
                        date: "2022-09-01",
                    },
                    {
                        sv: 390850,
                        date: "2022-10-01",
                    },
                    {
                        sv: 460170,
                        date: "2022-11-01",
                    },
                    {
                        sv: 504890,
                        date: "2022-12-01",
                    },
                    {
                        sv: 520240,
                        date: "2023-01-01",
                    },
                    {
                        sv: 509750,
                        date: "2023-02-01",
                    },
                    {
                        sv: 587610,
                        date: "2023-03-01",
                    },
                    {
                        sv: 561830,
                        date: "2023-04-01",
                    },
                    {
                        sv: 503000,
                        date: "2023-05-01",
                    },
                    {
                        sv: 403960,
                        date: "2023-06-01",
                    },
                    {
                        sv: 380800,
                        date: "2023-07-01",
                    },
                    {
                        sv: 387650,
                        date: "2023-08-01",
                    },
                    {
                        sv: 385340,
                        date: "2023-09-01",
                    },
                    {
                        sv: 455600,
                        date: "2023-10-01",
                    },
                    {
                        sv: 538810,
                        date: "2023-11-01",
                    },
                    {
                        sv: 232350,
                        date: "2023-12-01",
                    },
                    {
                        sv: 245650,
                        date: "2024-01-01",
                    },
                    {
                        sv: 284300,
                        date: "2024-02-01",
                    },
                    {
                        sv: 274480,
                        date: "2024-03-01",
                    },
                    {
                        sv: 289140,
                        date: "2024-04-01",
                    },
                    {
                        sv: 298020,
                        date: "2024-05-01",
                    },
                    {
                        sv: 245450,
                        date: "2024-06-01",
                    },
                    {
                        sv: 493360,
                        date: "2024-07-01",
                    },
                    {
                        sv: 163160,
                        date: "2024-08-01",
                    },
                    {
                        sv: 272860,
                        date: "2024-09-01",
                    },
                    {
                        sv: 532760,
                        date: "2024-10-01",
                    },
                ],
                topic: "face cream",
            },
            {
                ts: [
                    {
                        sv: 382440,
                        date: "2020-11-01",
                    },
                    {
                        sv: 404550,
                        date: "2020-12-01",
                    },
                    {
                        sv: 465480,
                        date: "2021-01-01",
                    },
                    {
                        sv: 457920,
                        date: "2021-02-01",
                    },
                    {
                        sv: 453120,
                        date: "2021-03-01",
                    },
                    {
                        sv: 444520,
                        date: "2021-04-01",
                    },
                    {
                        sv: 397340,
                        date: "2021-05-01",
                    },
                    {
                        sv: 285940,
                        date: "2021-06-01",
                    },
                    {
                        sv: 274650,
                        date: "2021-07-01",
                    },
                    {
                        sv: 307640,
                        date: "2021-08-01",
                    },
                    {
                        sv: 292940,
                        date: "2021-09-01",
                    },
                    {
                        sv: 359040,
                        date: "2021-10-01",
                    },
                    {
                        sv: 385420,
                        date: "2021-11-01",
                    },
                    {
                        sv: 417580,
                        date: "2021-12-01",
                    },
                    {
                        sv: 458420,
                        date: "2022-01-01",
                    },
                    {
                        sv: 420920,
                        date: "2022-02-01",
                    },
                    {
                        sv: 475260,
                        date: "2022-03-01",
                    },
                    {
                        sv: 439860,
                        date: "2022-04-01",
                    },
                    {
                        sv: 397890,
                        date: "2022-05-01",
                    },
                    {
                        sv: 328340,
                        date: "2022-06-01",
                    },
                    {
                        sv: 316330,
                        date: "2022-07-01",
                    },
                    {
                        sv: 328910,
                        date: "2022-08-01",
                    },
                    {
                        sv: 343740,
                        date: "2022-09-01",
                    },
                    {
                        sv: 390850,
                        date: "2022-10-01",
                    },
                    {
                        sv: 460170,
                        date: "2022-11-01",
                    },
                    {
                        sv: 504890,
                        date: "2022-12-01",
                    },
                    {
                        sv: 520240,
                        date: "2023-01-01",
                    },
                    {
                        sv: 509750,
                        date: "2023-02-01",
                    },
                    {
                        sv: 587610,
                        date: "2023-03-01",
                    },
                    {
                        sv: 561830,
                        date: "2023-04-01",
                    },
                    {
                        sv: 503000,
                        date: "2023-05-01",
                    },
                    {
                        sv: 403960,
                        date: "2023-06-01",
                    },
                    {
                        sv: 380800,
                        date: "2023-07-01",
                    },
                    {
                        sv: 387650,
                        date: "2023-08-01",
                    },
                    {
                        sv: 385340,
                        date: "2023-09-01",
                    },
                    {
                        sv: 455600,
                        date: "2023-10-01",
                    },
                    {
                        sv: 538810,
                        date: "2023-11-01",
                    },
                    {
                        sv: 582350,
                        date: "2023-12-01",
                    },
                    {
                        sv: 645650,
                        date: "2024-01-01",
                    },
                    {
                        sv: 584300,
                        date: "2024-02-01",
                    },
                    {
                        sv: 674480,
                        date: "2024-03-01",
                    },
                    {
                        sv: 589140,
                        date: "2024-04-01",
                    },
                    {
                        sv: 498020,
                        date: "2024-05-01",
                    },
                    {
                        sv: 445450,
                        date: "2024-06-01",
                    },
                    {
                        sv: 393360,
                        date: "2024-07-01",
                    },
                    {
                        sv: 363160,
                        date: "2024-08-01",
                    },
                    {
                        sv: 372860,
                        date: "2024-09-01",
                    },
                    {
                        sv: 432760,
                        date: "2024-10-01",
                    },
                ],
                topic: "face moisturizer",
            },
            {
                ts: [
                    {
                        sv: 17210,
                        date: "2020-11-01",
                    },
                    {
                        sv: 17670,
                        date: "2020-12-01",
                    },
                    {
                        sv: 19200,
                        date: "2021-01-01",
                    },
                    {
                        sv: 18610,
                        date: "2021-02-01",
                    },
                    {
                        sv: 19910,
                        date: "2021-03-01",
                    },
                    {
                        sv: 20210,
                        date: "2021-04-01",
                    },
                    {
                        sv: 18400,
                        date: "2021-05-01",
                    },
                    {
                        sv: 16620,
                        date: "2021-06-01",
                    },
                    {
                        sv: 15080,
                        date: "2021-07-01",
                    },
                    {
                        sv: 16050,
                        date: "2021-08-01",
                    },
                    {
                        sv: 15320,
                        date: "2021-09-01",
                    },
                    {
                        sv: 16120,
                        date: "2021-10-01",
                    },
                    {
                        sv: 17360,
                        date: "2021-11-01",
                    },
                    {
                        sv: 16620,
                        date: "2021-12-01",
                    },
                    {
                        sv: 17590,
                        date: "2022-01-01",
                    },
                    {
                        sv: 17450,
                        date: "2022-02-01",
                    },
                    {
                        sv: 20520,
                        date: "2022-03-01",
                    },
                    {
                        sv: 20020,
                        date: "2022-04-01",
                    },
                    {
                        sv: 20670,
                        date: "2022-05-01",
                    },
                    {
                        sv: 17850,
                        date: "2022-06-01",
                    },
                    {
                        sv: 18150,
                        date: "2022-07-01",
                    },
                    {
                        sv: 19440,
                        date: "2022-08-01",
                    },
                    {
                        sv: 18030,
                        date: "2022-09-01",
                    },
                    {
                        sv: 18570,
                        date: "2022-10-01",
                    },
                    {
                        sv: 20290,
                        date: "2022-11-01",
                    },
                    {
                        sv: 21540,
                        date: "2022-12-01",
                    },
                    {
                        sv: 21580,
                        date: "2023-01-01",
                    },
                    {
                        sv: 20800,
                        date: "2023-02-01",
                    },
                    {
                        sv: 23580,
                        date: "2023-03-01",
                    },
                    {
                        sv: 22900,
                        date: "2023-04-01",
                    },
                    {
                        sv: 23550,
                        date: "2023-05-01",
                    },
                    {
                        sv: 22400,
                        date: "2023-06-01",
                    },
                    {
                        sv: 21780,
                        date: "2023-07-01",
                    },
                    {
                        sv: 21670,
                        date: "2023-08-01",
                    },
                    {
                        sv: 21010,
                        date: "2023-09-01",
                    },
                    {
                        sv: 22290,
                        date: "2023-10-01",
                    },
                    {
                        sv: 23550,
                        date: "2023-11-01",
                    },
                    {
                        sv: 23160,
                        date: "2023-12-01",
                    },
                    {
                        sv: 25030,
                        date: "2024-01-01",
                    },
                    {
                        sv: 24560,
                        date: "2024-02-01",
                    },
                    {
                        sv: 24360,
                        date: "2024-03-01",
                    },
                    {
                        sv: 24340,
                        date: "2024-04-01",
                    },
                    {
                        sv: 23790,
                        date: "2024-05-01",
                    },
                    {
                        sv: 21910,
                        date: "2024-06-01",
                    },
                    {
                        sv: 21890,
                        date: "2024-07-01",
                    },
                    {
                        sv: 20600,
                        date: "2024-08-01",
                    },
                    {
                        sv: 19810,
                        date: "2024-09-01",
                    },
                    {
                        sv: 20760,
                        date: "2024-10-01",
                    },
                ],
                topic: "skin moisturizer",
            },
        ],
    } = slideContent;

    for (let i = 0; i < data.length; i++) {
        if (i === 0) data[i].itemColor = colors.three;
        else if (i === 1) data[i].itemColor = colors.two;
        else data[i].itemColor = colors.one;
    }

    return (
        <Layout showFull={showFull}>
            <div className="flex flex-col h-[720px] font-poppins">
                <div className="flex flex-col gap-2 mt-10">
                    <h1 className="text-black text-[34px] font-medium">{h1}</h1>
                    <h2 className="text-eyvablue text-sm font-semibold leading-[42px]">
                        {h2.toUpperCase()}
                    </h2>
                </div>
                <div className="flex-1 mt-20">
                    <ReactEcharts
                        option={getOptions({ data })}
                        opts={{ renderer: "svg" }}
                        style={{ width: "1150px", height: "450px" }}
                    />
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default Comparison;
