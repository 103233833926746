import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const GoogleData = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        info = "ERROR",
        imageURL = [
            "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/report_images/CI_v2/CI_V2_4.png",
            "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/report_images/CI_v2/CI_V2_5.png",
        ],
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full flex flex-col bg-white">
                <div className="flex h-[394px]">
                    {imageURL.map((item, id) => (
                        <img
                            key={id}
                            loading="lazy"
                            srcSet={item}
                            className="object-cover w-full"
                            alt=""
                        />
                    ))}
                </div>

                <div className="h-[296px] flex items-center px-16 bg-purple text-white">
                    <p className="text-[34px] font-normal flex-1">{h1}</p>
                    <div className="flex-1">
                        <p className="text-base font-medium font-poppins">
                            {h2}
                        </p>
                        <div dangerouslySetInnerHTML={{ __html: info }} />
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default GoogleData;
