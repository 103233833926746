import { formatNumberCompact } from "../../../utils/formatNumber";
import Footer from "../_generic/Footer";
import { GrowthText } from "../_generic/GrowthText";
import { Layout } from "../_generic/Layout";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";

const FrequentQuestions = ({
    slideContent,
    slideName,
    reportVersion,
    reportName,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { h1 = "ERROR", keywords = [], dataTitle = "ERROR" } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="w-full h-[720px]">
                <div className="flex flex-col col-span-6 mx-[50px]">
                    <div className="flex flex-col mt-10 mb-16">
                        <h1 className="text-black text-[34px] font-medium">
                            {h1}
                        </h1>
                        <h2 className="text-eyvablue text-sm font-semibold leading-[42px]">
                            {dataTitle.toUpperCase()}
                        </h2>
                    </div>

                    <div>
                        <table className="w-full">
                            <thead className="text-xs text-black/50 pb-3">
                                <tr>
                                    <th className="text-left font-normal">
                                        Combination
                                    </th>
                                    <th className="text-right font-normal">
                                        Search Volume
                                    </th>
                                    <th className="text-right font-normal">
                                        YoY Growth
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {keywords.map((item, id) => (
                                    <tr
                                        key={id}
                                        className="border-b border-black text-black text-sm"
                                    >
                                        <td className="py-3 font-normal leading-10 first-letter:capitalize">
                                            {item.keyword}
                                        </td>
                                        <td className="text-right font-medium py-3 leading-10">
                                            {formatNumberCompact(item.sv)}
                                        </td>
                                        <td className="flex justify-end py-3">
                                            <GrowthText
                                                growth={item.yoy}
                                                className="text-right font-medium leading-10"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default FrequentQuestions;
