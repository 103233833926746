import ReactEcharts from "echarts-for-react";

import Footer from "../_generic/Footer";

import { Layout } from "../_generic/Layout";
import { formatNumberCompact } from "../../../utils/formatNumber";
import { GrowthText } from "../_generic/GrowthText";

const colors = {
    one: "#EB966E",
    two: "#9A2888",
    three: "#272090",
};

const getOptions = ({ data, lang = "en" }) => {
    // Find the reference item
    const refItem = data.find((item) => item.ref);
    const maxValue = refItem
        ? refItem.searchVolume
        : Math.max(...data.map((item) => item.searchVolume));

    // Calculate relative values
    const relativeData = data.map((item) => ({
        ...item,
        relativeValue: item.searchVolume / maxValue,
    }));

    // Sort the data by relative value, keeping the reference item at the end
    relativeData.sort((a, b) => {
        if (a.ref) return 1;
        if (b.ref) return -1;
        return a.relativeValue - b.relativeValue;
    });

    // Find the largest value among non-reference topics
    const largestNonRefItem = relativeData
        .filter((item) => !item.ref)
        .reduce((prev, current) =>
            prev.searchVolume > current.searchVolume ? prev : current,
        );

    // Calculate the ratio
    const ratio = largestNonRefItem.searchVolume / refItem.searchVolume;


    // Construct dynamic xAxisText
    let comparison;
    let ratioText;

    if (ratio > 1) {
        comparison = "greater than for ";
        ratioText = `${Number(ratio.toFixed(2))}x`;
    } else if (ratio < 1) {
        comparison = "less than for ";
        ratioText = `${Number((1 / ratio).toFixed(2))}x`;
    } else {
        comparison = "equal to ";
        ratioText = "";
    }

    let xAxisText = `The monthly search volume (sv) for ${largestNonRefItem.topic} is \n${ratioText} ${comparison}${refItem.topic}`;

    if (lang === "de") {
        if (ratio > 1) {
            comparison = "größer";
            ratioText = `um das ${Number(ratio.toFixed(2))}-fache`;
        } else if (ratio < 1) {
            comparison = "geringer";
            ratioText = `um das ${Number((1 / ratio).toFixed(2))}-fache`;
        } else {
            comparison = "gleich";
            ratioText = "";
        }

        xAxisText = `Das monatliche Suchvolumen (sv) für ${largestNonRefItem.topic} ist \n${ratioText} ${comparison} als für ${refItem.topic}`;
    }

    return {
        animation: false,
        grid: {
            left: "-20px", // Adjust as needed
            right: "10px", // Adjust as needed
            top: "50px", // Adjust as needed
            bottom: "80px", // Adjust as needed
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: relativeData.map((item) => item.topic),
            name: xAxisText,
            nameLocation: "middle",
            nameGap: 60,
            nameTextStyle: {
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Poppins",
                lineHeight: 21,
                color: "#3A3A3A",
            },
            axisTick: {
                show: false, // Hide the axis ticks
            },
            axisLabel: {
                fontFamily: "Poppins", // Set the font family for x-axis labels
                fontStyle: "normal", // You can set it to 'italic' or 'normal'
                fontSize: 14, // Set the font size
                lineHeight: 21,
                fontWeight: 400,
                color: "#3A3A3A",
                // You can add more styling properties as needed
            },
        },
        yAxis: {
            show: false,
        },
        series: [
            {
                data: relativeData.map((item) => ({
                    value: item.relativeValue, // This is the relative value
                    itemStyle: {
                        color: item.itemColor,
                    },
                    label: {
                        show: true,
                        position: "top",
                        distance: 10,
                        formatter: function (params) {
                            // params.dataIndex gives the index of the data item
                            const actualValue =
                                relativeData[params.dataIndex].searchVolume;
                            const formattedValue = formatNumberCompact(actualValue);

                            // Format the relative value to remove unnecessary trailing zeros
                            const relativeValueText =
                                params.value === 1
                                    ? "1x"
                                    : `${Number(params.value.toFixed(2))}x`; // Using Number() removes trailing zeros

                            return `{large|${relativeValueText}}\n(sv = {normal|${formattedValue}})`;
                        },
                        rich: {
                            large: {
                                fontSize: 14,
                                lineHeight: 21,
                                fontWeight: 400,
                                align: "center",
                                fontFamily: "Poppins",
                                color: "#3A3A3A",
                            },
                            normal: {
                                fontSize: 12,
                                align: "center",
                                fontWeight: 400,
                                fontStyle: "italic",
                                fontFamily: "Poppins",
                                lineHeight: 18,
                                color: "#3A3A3A",
                            },
                        },
                    },
                })),
                type: "bar",
            },
        ],
    };
};

const About = ({
    slideContent,
    slideName,
    reportName,
    reportCreatedAt,
    reportVersion,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    const {
        data = [
            {
                ref: true,
                yoy: 9.117169889980993,
                topic: "face cream",
                multiplier: "1x",
                searchVolume: 532760,
            },
            {
                ref: false,
                yoy: 8.117169889980993,
                topic: "face moisturizer",
                multiplier: "1.0x",
                searchVolume: 432760,
            },
            {
                ref: false,
                yoy: 3.937127453585937,
                topic: "skin moisturizer",
                multiplier: "0.0x",
                searchVolume: 20760,
            },
        ],
        paragraphs = [
            {
                header: "Rising Popularity of Face Moisturizers 🌟",
                paragraph:
                    "<p>The search volume for face moisturizers is significantly higher compared to skin moisturizers, indicating a strong consumer interest in this category. The year-over-year growth for face moisturizers is also notably higher, suggesting an increasing trend in consumer demand. This could be attributed to growing awareness about skincare routines and the importance of facial care. The consistent interest in face moisturizers, regardless of reference status, highlights its established position in the market.</p><p>Overall, face moisturizers are experiencing a robust growth trajectory, making them a key focus area for beauty brands.</p>",
            },
            {
                header: "Moderate Interest in Skin Moisturizers 📉",
                paragraph:
                    "<p>Skin moisturizers, while still relevant, show a much lower search volume compared to face moisturizers. The year-over-year growth is also modest, indicating a slower increase in consumer interest. This could be due to the broader category of skin moisturizers, which may not be as targeted or specific as face moisturizers. Consumers might be more inclined to search for products that cater specifically to facial care, which is often perceived as more delicate and requiring specialized products.</p><p>In summary, skin moisturizers are maintaining a steady presence but are overshadowed by the more dynamic growth of face moisturizers.</p>",
            },
        ],
        h1 = "ERROR",
        title = "ERROR",
        comparisonNote = "ERROR",
        comparisonTitle = "ERROR",
    } = slideContent;

    for (const d of data) {
        if (d.ref) d.itemColor = colors.one;
        else if (d.itemColor === colors.two) d.itemColor = colors.two;
        else d.itemColor = colors.three;
    }


    const { searchVolume, yoy = null } = data.filter((d) => d.ref)[0];

    return (
        <Layout showFull={showFull}>
            <div className="w-full h-[720px] font-poppins">
                <div className="grid grid-cols-2 gap-12 h-full">
                    <div className="flex flex-col col-span-1 ml-10">
                        <div className="flex flex-col mt-10">
                            <h1 className="text-black text-[34px] font-medium ">
                                {h1}
                            </h1>
                            <h2 className="text-eyvablue text-sm font-semibold leading-[42px]">
                                {title.toUpperCase()}
                            </h2>
                        </div>

                        <div className="text-sm font-normal mt-[50px]">
                            <div
                                className="flex items-center
                                 py-4 text-black text-xs font-normal gap-10
                                "
                            >
                                <div>
                                    <p className="text-2xl font-medium">
                                        {formatNumberCompact(searchVolume)}
                                    </p>
                                    <p className="text-sm font-normal">
                                        Search Volume
                                    </p>
                                </div>
                                <div>
                                    <GrowthText
                                        growth={yoy}
                                        className="text-2xl font-medium"
                                    />
                                    <p className="text-sm font-normal">
                                        YoY Growth
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 overflow-y-scroll text-black max-h-[300px] mt-[35px] mb-20">
                            {paragraphs.map((p) => (
                                <div key={p.header}>
                                    <h3 className="text-[20px] font-semibold">
                                        {p.header}
                                    </h3>
                                    <p
                                        className="text-sm font-normal"
                                        dangerouslySetInnerHTML={{
                                            __html: p.paragraph,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-span-1 bg-zinc-100">
                        <div className="flex-col items-start self-stretch justify-center mt-14 mx-12">
                            <h3 className="text-[20px] font-semibold text-black mb-28">
                                {comparisonTitle}
                            </h3>

                            <div>
                                <ReactEcharts
                                    option={getOptions({ data })}
                                    opts={{ renderer: "svg" }}
                                    style={{ width: "500px", height: "300px" }}
                                ></ReactEcharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportCreatedAt={reportCreatedAt}
                reportVersion={reportVersion}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default About;
