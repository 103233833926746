import React, { useState } from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { Rating } from "../_generic/Rating";
import { SocialMediaPost } from "../_generic/SocialMediaPost";

export const TiktokPosts = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    if (slideContent.data.length === 0) {
        slideContent.data = [
            {
                profile: {
                    id: "6843404455121486854",
                    handle: "@dr.emi",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6843404455121486854.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 107480,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7438674868750535969.jpg",
                    caption:
                        "Meine Kopfhaut routine. Ich zuehe dss mindestens 1x/ woche durch. Noch besser wäre es natürlich, wenn ihr das 3-5 mal die Woche machen würdet. Ihr braucht kein spezielles Öl einfach nur eure Hände.  ##kopfhautmassage##kopfhaut##haarwachstum##haarwachstumtipps##haarausfall##fyp##fy##fürdich",
                    published_at: "2024-11-18",
                    external_url:
                        "https://www.tiktok.com/@dr.emi/video/7438674868750535969",
                    kpis: {
                        likes: 2694,
                        comments: 62,
                        engagement_rate: "87.00508306230159503000",
                        views: 86312,
                    },
                },
            },
            {
                profile: {
                    id: "6878690757898404870",
                    handle: "@scienskin",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6878690757898404870.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 1213,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7437799314132389152.jpg",
                    caption:
                        "🌿Reine ÖLE als Hautpflege? 👀 ➡️ Natürlich ist nicht automatisch besser! Schwarzkümmelöl wird oft als Wundermittel gehyped, aber dieses Video hat mich etwas geschockt. Reines Öl – auch Schwarzkümmelöl – wirkt lediglich weichmachend (emollient) und kann keine Feuchtigkeit spenden, weil die Wasserbindungsfähigkeit fehlt. Und dass ‘natürlich’ auch Risiken birgt, wird oft vergessen: Natürliche Öle enthalten oft Allergene, die z.B Kontaktdermatitis auslösen können. In Kosmetika werden Inhaltsstoffe eingesetzt, die auf die Haut abgestimmt sind. Lass dich nicht von Halbwissen täuschen! 🧴🔎  Barel, A.O., Paye, M., & Maibach, H.I. (Eds.). (2010). Handbook of Cosmetic Science and Technology (3rd ed.). CRC Press. https://doi.org/10.1201/b15273 Gaudin O, Toukal F, Hua C, et al. Association Between Severe Acute Contact Dermatitis Due to Nigella sativa Oil and Epidermal Apoptosis. JAMA Dermatol. 2018;154(9):1062–1065. doi:10.1001/jamadermatol.2018.2120 Loden, M. (2005). The clinical benefit of moisturizers. Journal of the European Academy of Dermatology and Venereology, 19(6), 672-688. Mawazi, S. M., Ann, J., Othman, N., Khan, J., Alolayan, S. O., Al thagfan, S. S., & Kaleemullah, M. (2022). A review of moisturizers; history, preparation, characterization and applications. Cosmetics, 9(3), 61. #faktencheck #mythenentlarven #wissenschaft #kosmetikmythen #schwarzkümmelöl  #bigpharma #pseudowissenschaft  #gesundheit #fakenews #skincare #gesundheitstipps #naturkosmetik #cleanbeauty #heilpraktiker #chemiefrei #toxinfrei #natürlichschön #alternativmedizin #alternativemedizin",
                    published_at: "2024-11-16",
                    external_url:
                        "https://www.tiktok.com/@scienskin/video/7437799314132389152",
                    kpis: {
                        likes: 2387,
                        comments: 112,
                        engagement_rate: "2590.9315746084089000",
                        views: 28898,
                    },
                },
            },
            {
                profile: {
                    id: "6907662055638582277",
                    handle: "@xskincare",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6907662055638582277.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 902049,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7436106958144408865.jpg",
                    caption:
                        "Es gibt möglichkeiten, aber gerade wenn ihr noch jung seid oder unsicher: bitte lasst eure werte und co erstmal von ärzten abchecken, sonst wird es schwierig. Lest auf jeden fall die verpackungsbeilage & dann erst entscheiden!  _ Pflegende Seren:  - transparent lab - hair density serum  - maru.derm - anti hairloss spray  - typebea - G1 serum  #skincare #haarausfall #hair #hautpflege #stitch #viral #haare  Cr: @Maria⚜️ ",
                    published_at: "2024-11-11",
                    external_url:
                        "https://www.tiktok.com/@xskincare/video/7436106958144408865",
                    kpis: {
                        likes: 81359,
                        comments: 785,
                        engagement_rate: "139.4534097471720600",
                        views: 1134688,
                    },
                },
            },
            {
                profile: {
                    id: "6843404455121486854",
                    handle: "@dr.emi",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6843404455121486854.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 107480,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7435927087942225185.jpg",
                    caption: "#erinnerung",
                    published_at: "2024-11-11",
                    external_url:
                        "https://www.tiktok.com/@dr.emi/video/7435927087942225185",
                    kpis: {
                        likes: 1492,
                        comments: 52,
                        engagement_rate: "26.42218630104972052900",
                        views: 25487,
                    },
                },
            },
            {
                profile: {
                    id: "6907662055638582277",
                    handle: "@xskincare",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6907662055638582277.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 902049,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7435756260118596897.jpg",
                    caption:
                        "bitte verlinkt mich nicht mehr, ich kann das video nicht mehr sehen 😭💛 hoffe es hilft euch weiter!  #skincare #fyp #hautpflege #stitch #pores #viral ",
                    published_at: "2024-11-10",
                    external_url:
                        "https://www.tiktok.com/@xskincare/video/7435756260118596897",
                    kpis: {
                        likes: 33592,
                        comments: 161,
                        engagement_rate: "50.55168727135464286400",
                        views: 413187,
                    },
                },
            },
            {
                profile: {
                    id: "6907662055638582277",
                    handle: "@xskincare",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6907662055638582277.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 902049,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7434896540193262880.jpg",
                    caption:
                        "EIN TREND DER GUT IST! Ob das von Rossmann, oder dm: beide wundsprays sind identisch & vom gleichen hersteller 💛  Cr: @Angela Mel  #fyp #skincare #drogerie #stitch #hautpflege #acne #pickel ",
                    published_at: "2024-11-08",
                    external_url:
                        "https://www.tiktok.com/@xskincare/video/7434896540193262880",
                    kpis: {
                        likes: 30352,
                        comments: 182,
                        engagement_rate: "56.64177679127426593300",
                        views: 468854,
                    },
                },
            },
            {
                profile: {
                    id: "6907662055638582277",
                    handle: "@xskincare",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6907662055638582277.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 902049,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7433889133514345761.jpg",
                    caption:
                        "LEUTE ALLE IM CHOR: ihr seid wunderschön, bitte bitte bitte, lasst euch nichts anderes einreden. Dieses Video hat mir das Herz zerbrochen - ALLES WIRD IRGENDWIE WIEDER IN ORDNUNG OK 🤧💛 _ Zink-Produkte: - jegliche Zinksalben aus der Apotheke oder Drogerie, sind zwar fettig, aber schützen vor dem Wasserverlust (erhöht ebenfalls die Regeneration) - altapharma/Mivolis wundspray, wirkt ebenfalls anti-entzündlich und beschleunigt die Regeneration, trocknet allerdings ohne Pflege aus, bitte eincremen nicht vergessen!  - windelcremes für Babies sind ebenfalls meist Zinkcremes, oft ohne Lanolin und weniger fettig, als eine Zinksalbe  __ Panthenol: - jegliche Panthenolsalben (5% mindestens)  - Gesichtswasser oder Seren sind ebenfalls sehr nützlich __ Azelainsäure: - Theramid Azid (15% Azelainsäure, nicht fettig)  - skincyclopedia eye cream (enthält 10%, kann man im Gesicht verwenden, sind 30ml) - ACmed Salbe (20%, weniger wirksam, leider etwas sandig, aber ist für den Preis mal einen Versuch wert)  Cr: @Vanessa <3  #fyp #skincare #hautpflege #drogerie #stitch #viral #acne ",
                    published_at: "2024-11-05",
                    external_url:
                        "https://www.tiktok.com/@xskincare/video/7433889133514345761",
                    kpis: {
                        likes: 68016,
                        comments: 278,
                        engagement_rate: "99.22866626508338880500",
                        views: 808983,
                    },
                },
            },
            {
                profile: {
                    id: "6843404455121486854",
                    handle: "@dr.emi",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6843404455121486854.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 107480,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7433342170561596705.jpg",
                    caption:
                        "Wusstet ihr das? Wie ist eure meinung dazu? Ich verstehe auf jeden Fall, dass man als Arzt doppelt und dreifach überdenken muss, was man in den sozialen Medien aussendet. Aber ich finde auch, dass alle andern auch strenger überwacht werden müssen, gerade dann, wenn halbwegs verbreitet wird. #ärzte #medizinstudium #medfluencer #skinfluencer #skincare #hautpflege #kittel #fyp #fy #fürdich ",
                    published_at: "2024-11-04",
                    external_url:
                        "https://www.tiktok.com/@dr.emi/video/7433342170561596705",
                    kpis: {
                        likes: 2542,
                        comments: 114,
                        engagement_rate: "64.32702981673710246000",
                        views: 63335,
                    },
                },
            },
            {
                profile: {
                    id: "6907662055638582277",
                    handle: "@xskincare",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6907662055638582277.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 902049,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7432385573370760481.jpg",
                    caption:
                        "Lippen rasieren 2024 war nicht so auf meiner bingo karte, BITTE LASST ES 😭 Milchsäure peelings gibt es viele, schaut da einfach, was ihr gut findet (hier balea & revuele, beide günstig und ausreichend). Die meisten lippenpflegen sind nicht feuchtigkeitsbindend, sondern einschliessend. Ein serum darunter & alles wird weich & gedchmeidig 🤝🏼✨ #fyp #skincare #hautpflege #drogerie #drogerie #stitch ",
                    published_at: "2024-11-01",
                    external_url:
                        "https://www.tiktok.com/@xskincare/video/7432385573370760481",
                    kpis: {
                        likes: 47393,
                        comments: 361,
                        engagement_rate: "92.08730254704005623700",
                        views: 766098,
                    },
                },
            },
            {
                profile: {
                    id: "6907662055638582277",
                    handle: "@xskincare",
                    platform: "tiktok",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/6907662055638582277.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 902049,
                    },
                },
                media: {
                    type: "video",
                    image_url:
                        "https://cloud.eyva.ai/tiktok/7426810279700270368.jpg",
                    caption:
                        "Can‘t relate tbh 🤧 #fyp #skincare #hautpflege #drogerie #viral #xskincare ",
                    published_at: "2024-10-17",
                    external_url:
                        "https://www.tiktok.com/@xskincare/video/7426810279700270368",
                    kpis: {
                        likes: 33370,
                        comments: 286,
                        engagement_rate: "56.83729435338499668300",
                        views: 469017,
                    },
                },
            },
        ];
    }

    let { dataTitle = "ERROR", h1 = "ERROR", data = [] } = slideContent;

    const DISPLAY_COUNT = 4;
    const [displayCount, setDisplayCount] = useState(DISPLAY_COUNT);

    const formattedPosts =
        data?.map((post) => ({
            username: post.profile.handle,
            userAvatar: post.profile.image_url,
            imageUrl: post.media.image_url,
            caption: post.media.caption,
            date: post.media.published_at,
            engagementRate: post.profile.kpis.engagement_rate,
            likes: post.media.kpis.likes,
            comments: post.media.kpis.comments,
            views: post.media.kpis?.views,
            externalUrl: post.media.external_url,
        })) ?? [];

    const handleShowMore = () => {
        setDisplayCount((prev) =>
            Math.min(prev + DISPLAY_COUNT, formattedPosts.length),
        );
    };

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-zinc-100 py-10 px-12 font-poppins">
                <h1 className="text-black text-4xl font-medium">{h1}</h1>
                <p className="mt-4 text-sm text-eyvablue font-semibold">
                    {dataTitle}
                </p>
                <div className="flex flex-col gap-7 items-center justify-center">
                    <div className="grid grid-cols-4 gap-4 text-black mt-10">
                        {formattedPosts
                            .slice(0, displayCount)
                            .map((post, index) => (
                                <SocialMediaPost
                                    key={index}
                                    post={post}
                                    platform="tiktok"
                                />
                            ))}
                    </div>
                    {displayCount < formattedPosts.length && (
                        <button
                            className="bg-purple text-white px-9 py-3 rounded-sm font-medium text-sm font-poppins"
                            onClick={handleShowMore}
                        >
                            Show More
                        </button>
                    )}
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TiktokPosts;
